import React, { useState } from 'react';
import { Mic, Image, Monitor, Smartphone } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTranslation } from '../../i18n/useTranslation.ts';

interface Feature {
  icon: React.ReactNode;
  title: string;
  description: string;
  color: string;
}

interface RoadmapTranslations {
  'flowagenda-roadmap': {
    title: string;
    subtitle: string;
    features: {
      voiceInput: {
        title: string;
        description: string;
      };
      imageRecognition: {
        title: string;
        description: string;
      };
      macosApp: {
        title: string;
        description: string;
      };
      iosApp: {
        title: string;
        description: string;
      };
    };
  };
}

const FutureRoadmap: React.FC = () => {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const features: Feature[] = [
    {
      icon: <Mic className="w-8 h-8" />,
      title: t('flowagenda-roadmap.features.voiceInput.title'),
      description: t('flowagenda-roadmap.features.voiceInput.description'),
      color: "from-purple-500 to-blue-500"
    },
    {
      icon: <Image className="w-8 h-8" />,
      title: t('flowagenda-roadmap.features.imageRecognition.title'),
      description: t('flowagenda-roadmap.features.imageRecognition.description'),
      color: "from-blue-500 to-cyan-500"
    },
    {
      icon: <Monitor className="w-8 h-8" />,
      title: t('flowagenda-roadmap.features.macosApp.title'),
      description: t('flowagenda-roadmap.features.macosApp.description'),
      color: "from-cyan-500 to-teal-500"
    },
    {
      icon: <Smartphone className="w-8 h-8" />,
      title: t('flowagenda-roadmap.features.iosApp.title'),
      description: t('flowagenda-roadmap.features.iosApp.description'),
      color: "from-teal-500 to-green-500"
    }
  ];

  return (
    <section className="py-24 relative overflow-hidden" id='roadmap'>
      <div className="absolute inset-0 bg-gradient-to-b from-white to-blue-50 dark:from-gray-900 dark:to-gray-800">
        <div className="absolute inset-0 bg-grid-black/[0.02] dark:bg-grid-white/[0.02]" />
      </div>

      <div className="container mx-auto px-6 relative">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16 relative"
        >
          <span className="absolute -top-12 left-1/2 -translate-x-1/2 text-9xl font-bold text-blue-500/5 dark:text-blue-400/5 select-none">
            ROADMAP
          </span>
          <h2 className="text-4xl font-bold mb-4 relative">
            <span className="bg-gradient-to-r from-blue-600 to-blue-400 dark:from-blue-400 dark:to-blue-200 bg-clip-text text-transparent">
              {t('flowagenda-roadmap.title')}
            </span>
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t('flowagenda-roadmap.subtitle')}
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="group relative"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="absolute -inset-0.5 bg-gradient-to-r opacity-0 group-hover:opacity-100 transition duration-500 blur-xl bg-gradient-to-r dark:opacity-20 dark:group-hover:opacity-100" />
              <div className="relative bg-white dark:bg-gray-800 rounded-2xl p-8 border-2 border-blue-500/10 dark:border-blue-400/10 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-xl">
                
                <div className="flex items-start gap-6">
                  <motion.div 
                    className="flex-shrink-0 p-3 bg-gradient-to-br from-blue-100 to-blue-50 dark:from-blue-900/50 dark:to-blue-800/50 rounded-lg"
                    animate={{
                      scale: hoveredIndex === index ? 1.1 : 1,
                    }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <div className="text-blue-600 dark:text-blue-400">
                      {feature.icon}
                    </div>
                  </motion.div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FutureRoadmap;