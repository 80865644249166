// src/FlowAgenda/index.tsx
import React from 'react';
import Hero from './components/Hero.tsx';
import Features from './components/Features.tsx';
import Navigation from './components/Navigation.tsx';
import FutureRoadmap from './components/RoadMap.tsx';
import Footer from './components/Footer.tsx';

const FlowAgenda = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
      <Navigation />
      <Hero />
      <Features />
      <FutureRoadmap />
      <Footer />
    </div>
  );
};

export default FlowAgenda;