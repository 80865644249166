// /Users/mac/Desktop/software_engineer_homepage/src/sections/Projects.tsx

import React from 'react';
import ProjectCard from '../components/ProjectCard.tsx';
import Tooltip from '../components/tooltip/Tooltip.tsx';
import { useTranslation } from '../i18n/useTranslation.ts';
import flowagenda_overview from '../media/flowagenda_overview.jpeg';

// Define the interface above the Projects component
interface Project {
  title: string;
  description: string;
  gradient: string;
  technologies: string[];
}

// Add type for the translation response
interface ProjectsTranslation {
  items: Project[];
  section: {
    title: string;
    description: string;
    tooltip: string;
    viewMore: string;
  };
}

const Projects = () => {
  const { t } = useTranslation();

  // Type the projects data
  const projects = t('projects.items') as Project[];

  return (
    <div className="relative overflow-display py-6 bg-white dark:bg-gray-900">
      <section className="max-w-6xl mx-auto p-4 sm:p-6 mt-16 sm:mt-24 dark:bg-gray-900" id="works">
        {/* Section Header */}
        <div className="mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-gray-900 dark:text-gray-100">
            {t('projects.section.title')}
          </h2>
          <p className="text-gray-600 text-lg max-w-2xl dark:text-gray-300">
            {t('projects.section.description')}
          </p>
        </div>
        
        {/* Projects Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Flop App Project */}
          <ProjectCard 
            {...projects[0]}
            imageUrl={flowagenda_overview}
            demoUrl="https://www.qiyijiazhen.com/flowagenda/"
            githubUrl="https://github.com/kiwigaze/FlowAgenda"
          />
        </div>

        {/* View More Projects Link */}
        <div className="mt-12 text-center">
          <Tooltip content={t('projects.section.tooltip')} position="bottom">
            <a
              href="https://github.com/yourusername"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors duration-300 dark:text-gray-300 dark:hover:text-gray-100"
            >
              <span>{t('projects.section.viewMore')}</span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>
          </Tooltip>
        </div>
      </section>
    </div>
  );
};

export default Projects;