import React, { useState } from 'react';
import { Github, Twitter, Linkedin, Mail, Download } from 'lucide-react';
import { GradientCard } from '../components/ui/index.tsx';
import { motion, useInView } from 'framer-motion';
import { useTranslation } from '../i18n/useTranslation.ts';
import selfie from '../media/selfie.jpeg';
import Tooltip from '../components/tooltip/Tooltip.tsx';

const SocialButton = ({ Icon, href = "#", label }) => {
  const { t } = useTranslation();
  
  return (
    <motion.a 
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="p-4 rounded-full transition-all duration-300"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      aria-label={t(label)}
    >
      <Icon className="w-6 h-6 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white" />
    </motion.a>
  );
};

const socialIcons = [
  { Icon: Github, href: "https://github.com/KiwiGaze", label: "hero.social.github" },
  { Icon: Twitter, href: "https://x.com/QiYijiazhen", label: "hero.social.twitter" },
  { Icon: Linkedin, href: "https://www.linkedin.com/in/yijiazhenqi/", label: "hero.social.linkedin" },
  { Icon: Mail, href: "mailto:qiyijiazhen@gmail.com", label: "hero.social.email" },
  { Icon: Download, href: "https://drive.google.com/file/d/1sCS7oYEzQsMz2oW85PXDIQAYEOPxf208/view?usp=sharing", label: "hero.social.resume", download: true }
];

const LoadingPlaceholder = () => (
  <div className="absolute inset-0 bg-gray-100 dark:bg-gray-700 animate-pulse rounded-3xl" />
);

const Hero = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2,
        type: "tween",
        duration: 0.5
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "tween",
        duration: 0.7,
        ease: "easeOut"
      }
    }
  };

  const socialIconsVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.6
      }
    }
  };

  const handleContactClick = () => {
    document.getElementById("contact")?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="relative overflow-display py-6 bg-white dark:bg-gray-900">
      <div className="max-w-6xl mx-auto p-4 sm:p-6 pt-32 sm:pt-32" id="home">
        <motion.div 
          className="grid grid-cols-1 lg:grid-cols-3 gap-8"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Image - Full width on mobile, 1/3 width on desktop */}
          <motion.div 
            className="lg:col-span-1 order-1 lg:order-2"
            variants={imageVariants}
          >
            <div className="relative h-[400px] lg:h-full w-full rounded-3xl overflow-hidden ring-1 ring-black/5 dark:ring-white/10 group">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-100/20 via-transparent to-indigo-50/20 dark:from-blue-900/20 dark:to-indigo-900/20 z-10
                group-hover:opacity-0 transition-opacity duration-300" />
              
              <div className="absolute inset-0 bg-gradient-to-br from-black/50 via-black/40 to-black/50 z-20
                opacity-0 group-hover:opacity-100 transition-all duration-300
                flex flex-col items-center justify-center gap-2 p-6">
                <span className="text-white text-xl font-medium transform translate-y-4 group-hover:translate-y-0
                  opacity-0 group-hover:opacity-100 transition-all duration-300">
                  {t('hero.image.viewProfile')}
                </span>
                <div className="flex flex-col items-center text-center">
                  <span className="text-white/90 text-sm transform translate-y-4 group-hover:translate-y-0
                    opacity-0 group-hover:opacity-100 transition-all duration-300 delay-75">
                    {t('hero.image.location')}
                  </span>
                  <span className="text-white/80 text-xs transform translate-y-4 group-hover:translate-y-0
                    opacity-0 group-hover:opacity-100 transition-all duration-300 delay-100">
                    {t('hero.image.city')}
                  </span>
                  <span className="text-white/70 text-xs mt-1 transform translate-y-4 group-hover:translate-y-0
                    opacity-0 group-hover:opacity-100 transition-all duration-300 delay-150">
                    {t('hero.image.date')}
                  </span>
                </div>
              </div>
              
              <div className="relative w-full h-full lg:aspect-[3/4] overflow-hidden">
                {!imageLoaded && <LoadingPlaceholder />}
                <img 
                  src={selfie}
                  alt={t('hero.image.viewProfile')}
                  className={`w-full h-full object-cover transition-all duration-300 
                    group-hover:scale-110 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                  decoding="async"
                  onLoad={() => setImageLoaded(true)}
                  sizes="(max-width: 768px) 100vw, 33vw"
                />
              </div>
            </div>
          </motion.div>

          {/* Content Card */}
          <div className="lg:col-span-2 order-2 lg:order-1 flex items-center">
            <GradientCard className="w-full bg-gradient-to-br from-blue-100/90 via-gray-100/90 to-indigo-50/90 dark:from-gray-800/90 dark:via-gray-900/90 dark:to-indigo-900/90 backdrop-blur-xl border border-white/30 dark:border-gray-700/30">
              <motion.div 
                className="p-6 sm:p-10 flex flex-col justify-center"
                variants={containerVariants}
              >
                <motion.h1 
                  variants={itemVariants}
                  className="text-4xl sm:text-5xl lg:text-5xl font-bold mb-8 sm:mb-10 bg-gradient-to-br from-gray-800 to-gray-900 dark:from-gray-100 dark:to-gray-200 bg-clip-text text-transparent leading-tight tracking-tight"
                >
                  {t<string>('hero.greeting')}
                  <span className="block mt-2">{t<string>('hero.role')}</span>
                </motion.h1>
                
                <motion.p 
                  variants={itemVariants}
                  className="text-gray-600 dark:text-gray-300 text-lg sm:text-xl lg:text-2xl mb-10 sm:mb-14 leading-relaxed"
                >
                  {t('hero.description')}
                </motion.p>
                
                <motion.div 
                  variants={itemVariants}
                  className="flex flex-col sm:flex-row items-center gap-6"
                >
                  <motion.button 
                    className="w-full sm:w-auto px-8 py-3 bg-black dark:bg-gray-700 text-white dark:text-gray-200 rounded-2xl font-medium shadow-sm hover:shadow-xl transition-all duration-300 hover:bg-gray-900 dark:hover:bg-gray-600"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleContactClick}
                  >
                    {t('hero.actions.contact')}
                  </motion.button>
                  <motion.div 
                    className="flex gap-6 mt-4 sm:mt-0"
                    variants={socialIconsVariants}
                  >
                    {socialIcons.map((item, index) => (
                      <motion.div
                        key={`hero-social-${index}`}
                        variants={itemVariants}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Tooltip content={t(item.label)} position="top">
                          <SocialButton {...item} />
                        </Tooltip>
                      </motion.div>
                    ))}
                  </motion.div>
                </motion.div>
              </motion.div>
            </GradientCard>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;