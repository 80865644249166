import React from 'react';
import { Github, Twitter, Linkedin, Mail, ArrowUp } from 'lucide-react';
import { useTranslation } from '../i18n/useTranslation.ts';

interface SocialLink {
  Icon: React.ElementType;
  href: string;
  label: string;
}

interface QuickLink {
  href: string;
  label: string;
}

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const socialLinks: SocialLink[] = [
    { Icon: Github, href: "https://github.com/KiwiGaze", label: String(t('footer.connect.social.github')) },
    { Icon: Twitter, href: "https://x.com/QiYijiazhen", label: String(t('footer.connect.social.twitter')) },
    { Icon: Linkedin, href: "https://www.linkedin.com/in/yijiazhenqi/", label: String(t('footer.connect.social.linkedin')) },
    { Icon: Mail, href: "mailto:qiyijiazhen@gmail.com", label: String(t('footer.connect.social.email')) }
  ];

  const quickLinks: QuickLink[] = [
    { href: '#home', label: String(t('footer.quickLinks.links.home')) },
    { href: '#about', label: String(t('footer.quickLinks.links.about')) },
    { href: '#works', label: String(t('footer.quickLinks.links.works')) },
    { href: '#experience', label: String(t('footer.quickLinks.links.experience')) },
    { href: '#contact', label: String(t('footer.quickLinks.links.contact')) }
  ];

  return (
    <footer className="mt-24 bg-gradient-to-br from-gray-50/80 to-slate-50/80 border-t border-gray-100 dark:bg-gradient-to-br dark:from-gray-800 dark:to-gray-900 dark:border-gray-700">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-12 sm:py-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Brand Section */}
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gradient-to-r from-sky-200 to-blue-200 dark:from-sky-600 dark:to-blue-600" />
              <span className="font-semibold text-gray-800 dark:text-gray-100">Qi Yijiazhen</span>
            </div>
            <p className="text-gray-600 dark:text-gray-300">
              {String(t('footer.brand.tagline'))}
            </p>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="font-semibold text-gray-800 dark:text-gray-100">
              {String(t('footer.quickLinks.title'))}
            </h3>
            <div className="grid grid-cols-2 gap-2">
              {quickLinks.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition-colors duration-300"
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>

          {/* Connect Section */}
          <div className="space-y-4">
            <h3 className="font-semibold text-gray-800 dark:text-gray-100">
              {String(t('footer.connect.title'))}
            </h3>
            <div className="flex gap-4">
              {socialLinks.map(({ Icon, href, label }) => (
                <a
                  key={href}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={label}
                  className="p-2 rounded-xl bg-white/80 dark:bg-gray-800/80 hover:bg-white dark:hover:bg-gray-700 hover:scale-110 hover:shadow-md transition-all duration-300 group"
                >
                  <Icon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 pt-8 border-t border-gray-100 dark:border-gray-700 flex flex-col sm:flex-row justify-between items-center gap-4">
          <p className="text-gray-600 dark:text-gray-300 text-sm">
            © {new Date().getFullYear()} Qi Yijiazhen. {String(t('footer.copyright'))}
          </p>
          <button
            onClick={scrollToTop}
            className="p-2 rounded-xl bg-white dark:bg-gray-800 hover:bg-white dark:hover:bg-gray-700 hover:scale-110 hover:shadow-md transition-all duration-300 group"
            aria-label={String(t('footer.scrollTop'))}
          >
            <ArrowUp className="w-5 h-5 text-gray-600 dark:text-gray-300" />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;