// src/FlowAgenda/components/Navigation.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../i18n/useTranslation.ts';
import { Menu, X, Globe, Check } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../media/logo.svg';

// Types and Interfaces
interface NavigationItem {
  name: string;
  href: string;
  isExternal?: boolean;
}

interface LanguageOption {
  code: 'en' | 'zh-CN' | 'zh-TW';
  name: string;
}

interface NavigationProps {
  initialLanguage?: LanguageOption['code'];
  onLanguageChange?: (langCode: LanguageOption['code']) => void;
  className?: string;
}

interface NavigationTranslations {
  'flowagenda-navigation': {
    logo: string;
    menu: {
      features: string;
      docs: string;
      github: string;
    };
    language: {
      selector: string;
      english: string;
      simplifiedChinese: string;
      traditionalChinese: string;
    };
    cta: string;
    accessibility: {
      openMenu: string;
      closeMenu: string;
      languageSelect: string;
    };
  };
}

const Navigation: React.FC<NavigationProps> = ({
  initialLanguage = 'en',
  onLanguageChange,
  className = '',
}) => {
  const { t, language, setLanguage } = useTranslation<NavigationTranslations>();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption['code']>(initialLanguage);

  useEffect(() => {
    const handleScroll = (): void => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setSelectedLanguage(language as LanguageOption['code']);
  }, [language]);

  // Animation variants
  const menuAnimationVariants = {
    hidden: {
      opacity: 0,
      y: -20,
      transformOrigin: "top",
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    }
  };

  const menuItemVariants = {
    hidden: { 
      opacity: 0, 
      y: -10,
      transition: {
        duration: 0.2
      }
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.3,
        ease: "easeOut"
      }
    }),
    exit: {
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.2
      }
    }
  };

  const handleLanguageChange = (langCode: LanguageOption['code']): void => {
    setLanguage(langCode);
    onLanguageChange?.(langCode);
    setIsMobileMenuOpen(false);
  };

  const scrollToSection = (sectionId: string): void => {
    const element = document.querySelector(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const navigationItems: NavigationItem[] = [
    {
      name: t('flowagenda-navigation.menu.features'),
      href: '#features',
      isExternal: false
    },
    {
      name: t('flowagenda-navigation.menu.docs'),
      href: '#documentation',
      isExternal: false
    },
    {
      name: t('flowagenda-navigation.menu.github'),
      href: 'https://github.com/KiwiGaze/FlowAgenda',
      isExternal: true
    },
  ];

  const languages: LanguageOption[] = [
    {
      code: 'en',
      name: t('flowagenda-navigation.language.english')
    },
    {
      code: 'zh-CN',
      name: t('flowagenda-navigation.language.simplifiedChinese')
    },
    {
      code: 'zh-TW',
      name: t('flowagenda-navigation.language.traditionalChinese')
    },
  ];

  const languageDisplayMap: Record<LanguageOption['code'], string> = {
    'en': 'En',
    'zh-CN': '中',
    'zh-TW': '繁'
  };

  const handleNavClick = (item: NavigationItem, e: React.MouseEvent) => {
    if (!item.isExternal) {
      e.preventDefault();
      scrollToSection(item.href);
      setIsMobileMenuOpen(false);
    }
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled 
        ? 'bg-white/80 backdrop-blur-lg shadow-sm border-b border-gray-100 dark:bg-gray-900/80 dark:border-gray-800' 
        : 'bg-transparent'
    } ${className}`.trim()}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex-shrink-0 flex items-center gap-2"
          >
            <img 
              src={logo}
              alt="FlowAgenda" 
              className="w-8 h-8"
            />
            <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-neutral-600 to-neutral-400 dark:from-neutral-300 dark:to-neutral-500 bg-clip-text text-transparent">
              {t('flowagenda-navigation.logo')}
            </span>
          </motion.div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navigationItems.map((item) => (
              <motion.a
                key={item.name}
                href={item.href}
                onClick={(e) => handleNavClick(item, e)}
                className="text-gray-600 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400 transition-colors text-sm font-medium"
                whileHover={{ y: -2 }}
                transition={{ type: "spring", stiffness: 300 }}
                target={item.isExternal ? "_blank" : undefined}
                rel={item.isExternal ? "noopener noreferrer" : undefined}
              >
                {item.name}
              </motion.a>
            ))}

            {/* Language Selector */}
            <div className="relative group">
              <motion.button 
                className="flex items-center space-x-2 text-gray-600 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400 text-sm font-medium"
                whileHover={{ y: -2 }}
                transition={{ type: "spring", stiffness: 300 }}
                aria-label={t('flowagenda-navigation.accessibility.languageSelect')}
              >
                <Globe className="w-4 h-4" />
                <span>{languageDisplayMap[selectedLanguage]}</span>
              </motion.button>
              <div className="absolute right-0 mt-2 w-48 rounded-xl shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 translate-y-2 group-hover:translate-y-0">
                {languages.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => handleLanguageChange(lang.code)}
                    className="flex items-center justify-between w-full px-4 py-2.5 text-sm text-gray-600 hover:text-blue-500 hover:bg-blue-50 dark:text-gray-300 dark:hover:text-blue-400 dark:hover:bg-gray-700/50 rounded-lg transition-colors"
                  >
                    {lang.name}
                    {selectedLanguage === lang.code && (
                      <Check className="w-4 h-4 text-blue-500" />
                    )}
                  </button>
                ))}
              </div>
            </div>

            {/* Get Started Button */}
            <motion.a
              href='https://flowagenda.qiyijiazhen.com'
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-5 py-2.5 rounded-xl bg-blue-500 text-white text-sm font-medium hover:bg-blue-600 transition-colors"
            >
              {t('flowagenda-navigation.cta')}
            </motion.a>
          </div>

          {/* Mobile Menu Button */}
          <motion.button
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded-xl text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-800 transition-colors"
            aria-label={t(
              isMobileMenuOpen 
                ? 'flowagenda-navigation.accessibility.closeMenu' 
                : 'flowagenda-navigation.accessibility.openMenu'
            )}
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </motion.button>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence mode="wait">
        {isMobileMenuOpen && (
          <motion.div
            key="mobile-menu"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={menuAnimationVariants}
            className="md:hidden bg-white dark:bg-gray-800 border-t border-gray-100 dark:border-gray-700 will-change-transform overflow-hidden"
            style={{ 
              position: 'absolute',
              width: '100%',
              top: '100%',
              left: 0
            }}
          >
            <motion.div 
              className="px-4 py-6 space-y-4"
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {navigationItems.map((item, index) => (
                <motion.a
                  key={item.name}
                  custom={index}
                  variants={menuItemVariants}
                  href={item.href}
                  onClick={(e) => handleNavClick(item, e)}
                  className="block px-4 py-2.5 rounded-xl text-gray-600 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400 hover:bg-blue-50 dark:hover:bg-gray-700/50 transition-colors text-sm font-medium"
                  target={item.isExternal ? "_blank" : undefined}
                  rel={item.isExternal ? "noopener noreferrer" : undefined}
                >
                  {item.name}
                </motion.a>
              ))}
              
              <motion.div 
                variants={menuItemVariants}
                custom={navigationItems.length}
                className="px-4 pt-4 border-t border-gray-100 dark:border-gray-700"
              >
                <p className="text-xs text-gray-400 dark:text-gray-500 mb-3">
                  {t('flowagenda-navigation.language.selector')}
                </p>
                <div className="grid grid-cols-1 gap-2">
                  {languages.map((lang, index) => (
                    <motion.button
                      key={lang.code}
                      variants={menuItemVariants}
                      custom={navigationItems.length + 1 + index}
                      onClick={() => handleLanguageChange(lang.code)}
                      className={`flex items-center justify-between px-4 py-2.5 rounded-lg text-sm font-medium transition-colors ${
                        selectedLanguage === lang.code
                          ? 'bg-blue-50 dark:bg-blue-900/50 text-blue-500 dark:text-blue-400'
                          : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                      }`}
                    >
                      {lang.name}
                      {selectedLanguage === lang.code && (
                        <Check className="w-4 h-4" />
                      )}
                    </motion.button>
                  ))}
                </div>
              </motion.div>
              
              <motion.div 
                variants={menuItemVariants}
                custom={navigationItems.length + languages.length + 1}
                className="px-4 pt-4"
              >
                <button className="w-full px-5 py-3 rounded-xl bg-blue-500 text-white text-sm font-medium hover:bg-blue-600 transition-colors">
                  {t('flowagenda-navigation.cta')}
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navigation;