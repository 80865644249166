import React, { useRef } from 'react';
import { Calendar, MapPin, Badge, Building, ChevronDown, ExternalLink, Star, Award, Target, Zap } from 'lucide-react';
import { motion, AnimatePresence, useAnimation, useInView } from 'framer-motion';
import Tooltip from '../components/tooltip/Tooltip.tsx';
import { useTranslation } from '../i18n/useTranslation.ts';

// Add this at the top of Experience.tsx, after the imports
interface Experience {
  title: string;
  company: string;
  period: string;
  location: string;
  achievements: {
    [key: string]: {
      metric: string;
      description: string;
      type?: string;
    };
  };
  responsibilities: string[];
  featured?: boolean;
  technologies: string[];
  companyUrl?: string;
}

const GlowingBadge = ({ children, color = "sky" }: { children: React.ReactNode; color?: string }) => (
  <div className={`
    inline-flex items-center px-3 py-1 rounded-full text-xs font-medium
    bg-${color}-50 dark:bg-${color}-800 text-${color}-800 dark:text-${color}-300
    border border-${color}-200/50 dark:border-${color}-700/50
    shadow-sm shadow-${color}-100/50 dark:shadow-${color}-700/50
    animate-glow
  `}>
    {children}
  </div>
);

const IconBadge = ({ Icon, label, color = "sky" }: { Icon: React.ElementType; label: string; color?: string }) => (
  <div className={`
    inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm
    bg-${color}-50 dark:bg-${color}-800 text-${color}-700 dark:text-${color}-300
    hover:bg-${color}-100 dark:hover:bg-${color}-700
    transition-all duration-300 group cursor-default
  `}>
    <Icon className={`w-3.5 h-3.5 group-hover:scale-110 transition-transform duration-300 dark:text-${color}-300`} />
    <span>{label}</span>
  </div>
);

const AchievementCard = ({ icon: Icon, metric, description }: { icon: React.ElementType; metric: string; description: string }) => (
  <div 
    className="flex items-start gap-4 p-4 rounded-xl bg-gradient-to-br from-white to-gray-50/80 dark:from-gray-800 dark:to-gray-700/80 border border-gray-100 dark:border-gray-700 hover:border-sky-100 dark:hover:border-sky-400 shadow-sm dark:shadow-md hover:shadow-md dark:hover:shadow-lg transition-all duration-300"
  >
    <div className="w-10 h-10 rounded-full bg-gradient-to-br from-sky-50 to-indigo-50 dark:from-sky-800 dark:to-indigo-800 flex items-center justify-center flex-shrink-0 border border-sky-100/50 dark:border-sky-700/50">
      <Icon className="w-5 h-5 text-sky-600 dark:text-sky-300" />
    </div>
    <div className="space-y-1">
      <div className="font-semibold text-gray-900 dark:text-gray-100">{metric}</div>
      <div className="text-sm text-gray-600 dark:text-gray-300 leading-relaxed">{description}</div>
    </div>
  </div>
);

const ExperienceCard = ({ experience, index, isActive, onClick, t }: { experience: any; index: number; isActive: boolean; onClick: (index: number) => void; t: any }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 50, scale: 0.85 }}
      animate={{ 
        opacity: 1, 
        y: 0,
        scale: isActive ? 1.05 : 1,
        transition: { duration: 0.6, ease: "easeOut" }
      }}
      onClick={() => onClick(index)}
      className={`
        relative rounded-3xl overflow-hidden
        ${isActive 
          ? 'cursor-default ring-2 ring-gray-700/5 shadow-lg dark:ring-gray-300/5 dark:shadow-xl' 
          : 'cursor-pointer hover:scale-[0.985] transform-gpu border border-slate-200 dark:border-slate-700'}
        absolute inset-x-0 transition-all duration-500
      `}
      style={{
        top: isActive ? `${index * 0}px` : `${index * 32}px`,
        zIndex: isActive ? 40 - index : 30 - index
      }}
    >
      <div className="absolute inset-0 rounded-2xl" />
      <div className={`
        absolute inset-0 bg-gradient-to-br 
        ${isActive ? 'from-white to-white dark:from-gray-800 dark:to-gray-800' : 'from-transparent to-transparent'} 
        rounded-2xl transition-color transition-opacity duration-500
      `} />
      
      <div className="relative p-6 sm:p-8">
        <div className="flex flex-col space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-[2.5fr,1fr] gap-6">
            <div className="space-y-4">
              <div className="flex flex-wrap items-center gap-3">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 bg-gradient-to-r from-gray-900 to-gray-700 dark:from-gray-100 dark:to-gray-300 bg-clip-text text-transparent">
                  {experience.title}
                </h3>
                {experience.featured && (
                  <GlowingBadge color="amber">
                    <Star className="w-3 h-3 mr-1" />
                    Featured
                  </GlowingBadge>
                )}
              </div>
              
              <div className="flex items-center gap-3 text-gray-600 dark:text-gray-300">
                <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-gray-50 to-sky-50 dark:from-gray-700 dark:to-sky-700 flex items-center justify-center border border-gray-200/80 dark:border-gray-700/80 shadow-sm dark:shadow-md group-hover:shadow transition-all duration-300">
                  <Building className="w-6 h-6 text-gray-600 dark:text-gray-500" />
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-gray-800 dark:text-gray-100">{experience.company}</span>
                    {experience.companyUrl && (
                      <a 
                        href={experience.companyUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={`Visit ${experience.company}'s website`}
                        className="p-1.5 rounded-lg hover:bg-sky-50 dark:hover:bg-sky-700 text-gray-400 dark:text-gray-500 hover:text-sky-600 dark:hover:text-sky-400 transition-all duration-300"
                      >
                        <ExternalLink className="w-4 h-4" />
                      </a>
                    )}
                  </div>
                  <div className="flex items-center gap-4 mt-1 text-sm text-gray-500 dark:text-gray-400">
                    <div className="flex items-center gap-1.5">
                      <Calendar className="w-4 h-4" />
                      <span>{experience.period}</span>
                    </div>
                    <div className="flex items-center gap-1.5">
                      <MapPin className="w-4 h-4" />
                      <span>{experience.location}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AnimatePresence>
            {isActive && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="overflow-hidden"
              >
                <div className="space-y-8 pt-4">
                  {experience.achievements && (
                    <div className="space-y-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 flex items-center gap-2">
                        <Award className="w-4 h-4 text-sky-600 dark:text-sky-300" />
                        {t('experience.common.keyAchievements')}
                      </h4>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {Object.entries(experience.achievements).map(([key, achievement]: [string, any]) => (
                          <AchievementCard
                            key={key}
                            icon={achievement.type === "scalability" ? Target : Zap}
                            metric={achievement.metric}
                            description={achievement.description}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {experience.technologies && (
                    <div className="space-y-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 flex items-center gap-2">
                        <Badge className="w-4 h-4 text-sky-600 dark:text-sky-300" />
                        {t('experience.common.technologies')}
                      </h4>
                      <div className="flex flex-wrap gap-2">
                        {experience.technologies.map((tech: string, idx: number) => (
                          <IconBadge key={idx} Icon={Badge} label={tech} />
                        ))}
                      </div>
                    </div>
                  )}

                  {experience.responsibilities && (
                    <div className="space-y-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 flex items-center gap-2">
                        <Target className="w-4 h-4 text-sky-600 dark:text-sky-300" />
                        {t('experience.common.keyResponsibilities')}
                      </h4>
                      <div className="grid gap-3">
                        {experience.responsibilities.map((item: string, idx: number) => (
                          <motion.div
                            key={idx}
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.1 + (idx * 0.1) }}
                            className="flex gap-3 group p-3 rounded-xl hover:bg-sky-50/50 dark:hover:bg-sky-800/50 transition-all duration-300"
                          >
                            <div className="relative mt-1.5">
                              <div className="w-1.5 h-1.5 rounded-full bg-sky-500 dark:bg-sky-400 group-hover:bg-sky-600 dark:group-hover:bg-sky-500 transition-colors duration-300" />
                            </div>
                            <p className="flex-1 text-gray-600 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-100 transition-colors duration-300">
                              {item}
                            </p>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <motion.div 
            className="flex justify-center"
            animate={{ opacity: isActive ? 0.5 : 1 }}
          >
            <motion.div
              animate={{ rotate: isActive ? 180 : 0 }}
              transition={{ duration: 0.3 }}
              className={`p-1.5 rounded-full ${isActive ? 'bg-gray-100 dark:bg-gray-800' : 'bg-transparent'}`}
            >
              <ChevronDown className={`w-5 h-5 ${isActive ? 'text-gray-600 dark:text-gray-300' : 'text-gray-400 dark:text-gray-500'}`} />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

const Experience = () => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const containerRef = useRef(null);
  const { t } = useTranslation();
  
  const isInView = useInView(containerRef, { 
    once: true,
    amount: 0.2
  });

  const handleCardClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const experiences: Experience[] = [
    {
      title: t('experience.roles.seniorEngineer.title'),
      company: t('experience.roles.seniorEngineer.company'),
      period: t('experience.roles.seniorEngineer.period'),
      location: t('experience.roles.seniorEngineer.location'),
      achievements: {
        scalability: {
          metric: t('experience.roles.seniorEngineer.achievements.scalability.metric'),
          description: t('experience.roles.seniorEngineer.achievements.scalability.description'),
        },
        engagement: {
          metric: t('experience.roles.seniorEngineer.achievements.engagement.metric'),
          description: t('experience.roles.seniorEngineer.achievements.engagement.description'),
        },
        efficiency: {
          metric: t('experience.roles.seniorEngineer.achievements.efficiency.metric'),
          description: t('experience.roles.seniorEngineer.achievements.efficiency.description'),
        }
      },
      responsibilities: t<string[]>('experience.roles.seniorEngineer.responsibilities', { returnObjects: true }),
      featured: true,
      technologies: ["Django", "Python", "Vue", "Retrieval-Augmented Generation (RAG)", "Prompt Engineering", "Git", "REST API", "Three.js", "Trello"],
      companyUrl: "https://hkstema.com"
    }
  ];

  const sectionVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        staggerChildren: 0.2
      }
    }
  };

  const headerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 60 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  return (
    <div className="relative overflow-display py-6 bg-white dark:bg-gray-900">
      <motion.div
        ref={containerRef}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={sectionVariants}
        className="max-w-6xl mx-auto p-4 sm:p-6 mt-16 sm:mt-24 dark:bg-gray-900"
        id="experience"
      >
        <motion.div
          variants={headerVariants}
          className="mb-12 dark:text-gray-100"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-gray-100 mb-4">
            {t('experience.title')}
          </h2>
          <motion.p className="text-gray-600 dark:text-gray-300 max-w-2xl">
            {t('experience.subtitle')}
          </motion.p>
        </motion.div>

        <div className="relative">
          {experiences.map((experience, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
            >
              <ExperienceCard
                experience={experience}
                index={index}
                isActive={activeIndex === index}
                onClick={handleCardClick}
                t={t}
              />
            </motion.div>
          ))}
        </div>

        {/* View More Link */}
        <motion.div 
          variants={headerVariants}
          className="mt-12 text-center"
        >
          <Tooltip content={t('experience.navigation.linkedinTooltip')} position="bottom">
            <a
              href="https://linkedin.com/in/yijiazhenqi/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 transition-colors duration-300"
            >
              <span>{t('experience.navigation.viewMore')}</span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>
          </Tooltip>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Experience;