import React, { useRef } from 'react';
import { Calendar, MapPin, Award, ExternalLink, BookOpen, Trophy } from 'lucide-react';
import { motion, useScroll, useTransform, useSpring, AnimatePresence } from 'framer-motion';
import { useTranslation } from '../i18n/useTranslation.ts';

// Interfaces
interface ThesisData {
  title: string;
  link: string;
}

interface EducationData {
  degree: string;
  university: string;
  location: string;
  period: string;
  achievements: string[];
  courses: string[];
  thesis: ThesisData;
}

interface SectionHeaders {
  keyCourses: string;
  achievements: string;
  thesisResearch: string;
}

interface EducationCardProps extends EducationData {
  index: number;
  sectionHeaders: SectionHeaders;
  viewPublicationText: string;
}

// Helper Components
const CourseTag = ({ course }: { course: string }) => {
  const getTagColor = (course: string) => {
    const categories = {
      // Add both English and Chinese keywords
      'Algorithms|算法|演算法': { bg: 'bg-blue-50/80 dark:bg-blue-900/80', text: 'text-blue-600 dark:text-blue-300', border: 'border-blue-100 dark:border-blue-700' },
      'Software|软件|軟體': { bg: 'bg-emerald-50/80 dark:bg-emerald-900/80', text: 'text-emerald-600 dark:text-emerald-300', border: 'border-emerald-100 dark:border-emerald-700' },
      'Networks|网络|網路': { bg: 'bg-purple-50/80 dark:bg-purple-900/80', text: 'text-purple-600 dark:text-purple-300', border: 'border-purple-100 dark:border-purple-700' },
      'Database|数据库|資料庫': { bg: 'bg-pink-50/80 dark:bg-pink-900/80', text: 'text-pink-600 dark:text-pink-300', border: 'border-pink-100 dark:border-pink-700' },
    };
    
    for (const [key, colors] of Object.entries(categories)) {
      // Split the key into possible matches and check if any match
      if (key.split('|').some(keyword => course.includes(keyword))) return colors;
    }
    return { bg: 'bg-slate-50/80 dark:bg-slate-800/80', text: 'text-slate-600 dark:text-slate-300', border: 'border-slate-100 dark:border-slate-700' };
  };

  const colors = getTagColor(course);
  
  return (
    <span className={`inline-flex items-center px-3 py-1 rounded-full 
                      text-sm font-medium ${colors.bg} ${colors.text} 
                      border ${colors.border} transition-colors duration-200
                      hover:bg-opacity-90 backdrop-blur-sm`}>
      {course}
    </span>
  );
};

const Achievement = ({ children, index }: { children: React.ReactNode; index: number }) => (
  <motion.div 
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3, delay: index * 0.1 }}
    className="flex items-center gap-3 px-4 py-3
               bg-gradient-to-r from-white to-slate-50/40 dark:from-gray-800 dark:to-slate-700/40 rounded-xl
               border border-slate-200 dark:border-slate-700 hover:border-sky-200 dark:hover:border-sky-400
               shadow-sm dark:shadow-md hover:shadow-md dark:hover:shadow-lg transition-all duration-300"
  >
    <div className="shrink-0">
      <Award className="w-5 h-5 text-amber-600 dark:text-amber-300" />
    </div>
    <span className="text-sm font-medium text-gray-900 dark:text-gray-100">{children}</span>
  </motion.div>
);

const CourseItem = ({ course, idx }: { course: string; idx: number }) => {
  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <motion.div 
      variants={itemVariants}
      whileHover={{ x: 10 }}
      className="flex items-start gap-3 p-2 rounded-lg hover:bg-sky-50/50 dark:hover:bg-sky-800/50 
                 transition-all duration-300 cursor-default"
    >
      <CourseTag course={course} />
    </motion.div>
  );
};

const StatusBadge = ({ period }: { period: string }) => {
  const { t } = useTranslation();
  const isOngoing = period.includes("Present") || 
                   parseInt(period.split(" - ")[1]) >= new Date().getFullYear();
  
  return (
    <div className={`inline-flex items-center px-3.5 py-1.5 rounded-full text-sm font-medium
                   ${isOngoing ? 'bg-sky-100/90 dark:bg-sky-800/90 text-sky-900 dark:text-sky-300 border-sky-200 dark:border-sky-700' : 
                               'bg-slate-100/90 dark:bg-slate-800/90 text-slate-800 dark:text-slate-300 border-slate-200 dark:border-slate-700'}
                   border-[1.5px] shadow-sm dark:shadow-md`}>
      <span className={`w-2 h-2 rounded-full mr-2
                     ${isOngoing ? 'bg-sky-600 dark:bg-sky-400' : 'bg-slate-600 dark:bg-slate-400'}`} />
      {isOngoing ? t('education.status.inProgress') : t('education.status.completed')}
    </div>
  );
};

const SectionHeader: React.FC<{ icon: React.ElementType; title: string }> = ({ icon: Icon, title }) => (
  <motion.div 
    className="flex items-center gap-2.5 text-gray-800 dark:text-gray-100 font-medium mb-5"
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
  >
    <Icon className="w-5 h-5" />
    <h4 className="text-base uppercase tracking-wide">{title}</h4>
  </motion.div>
);

const ParallaxDecoration = () => {
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
  });
  
  const y1 = useSpring(useTransform(scrollYProgress, [0, 1], [0, -50]), {
    damping: 15,
    stiffness: 50
  });
  
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <motion.div
        className="absolute top-20 -left-20 w-72 h-72 bg-gradient-to-br from-sky-200/20 to-transparent 
                   dark:from-sky-900/20 rounded-full transform-gpu"
        style={{ y: y1 }}
      />
      <motion.div
        className="absolute bottom-20 -right-20 w-72 h-72 bg-gradient-to-br from-purple-200/20 to-transparent 
                   dark:from-purple-900/20 rounded-full transform-gpu"
        style={{ y: y1 }}
      />
    </div>
  );
};

// Education Card Component
const EducationCard: React.FC<EducationCardProps> = ({ 
  degree, 
  university, 
  location, 
  period, 
  achievements, 
  courses, 
  thesis, 
  index,
  sectionHeaders,
  viewPublicationText
}) => {
  const ref = useRef(null);
  
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"]
  });
  
  const cardY = useSpring(
    useTransform(scrollYProgress, [0, 1], [50, 0]),
    { damping: 15, stiffness: 50 }
  );

  return (
    <motion.div
      ref={ref}
      className="group transform-gpu"
      style={{ y: cardY }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.5 }}
    >
      <div className="relative rounded-3xl border border-slate-200 dark:border-slate-700 
                    hover:border-sky-200 dark:hover:border-sky-400 
                    shadow-sm dark:shadow-md transform-gpu
                    transition-all duration-300 ease-out">
        <div className="p-6 sm:p-8">
          <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-4 mb-6">
            <div className="space-y-2">
              <h3 className="text-xl font-semibold bg-gradient-to-r from-gray-900 to-gray-700 
                           dark:from-gray-100 dark:to-gray-300 bg-clip-text text-transparent">
                {degree}
              </h3>
              <div className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                <MapPin className="w-4 h-4 text-gray-400 dark:text-gray-500" />
                <span className="font-medium">{university}</span>
                <span className="text-gray-300 dark:text-gray-600">•</span>
                <span>{location}</span>
              </div>
            </div>
            
            <div className="shrink-0 space-y-2">
              <div className="flex items-center gap-2 text-slate-600 dark:text-slate-300">
                <Calendar className="w-4 h-4" />
                <span>{period}</span>
                <StatusBadge period={period} />
              </div>
            </div>
          </div>
          
          <div className="grid md:grid-cols-2 gap-6 sm:gap-8">
            <div className="space-y-4">
              {courses && (
                <div>
                  <SectionHeader icon={BookOpen} title={sectionHeaders.keyCourses} />
                  <div className="space-y-2.5">
                    {courses.map((course, idx) => (
                      <CourseItem key={idx} course={course} idx={idx} />
                    ))}
                  </div>
                </div>
              )}
            </div>
            
            <div className="space-y-4">
              {achievements && (
                <div>
                  <SectionHeader icon={Trophy} title={sectionHeaders.achievements} />
                  <div className="space-y-3">
                    {achievements.map((achievement, idx) => (
                      <Achievement key={idx} index={idx}>
                        {achievement}
                      </Achievement>
                    ))}
                  </div>
                </div>
              )}
              
              {/*thesis && (
                <motion.div 
                  className="bg-gradient-to-br from-sky-50/50 dark:from-sky-800/50 to-indigo-50/50 dark:to-indigo-800/50 rounded-xl p-4 
                            hover:shadow-md dark:hover:shadow-lg transition-all duration-300"
                  whileHover={{ scale: 1.02 }}
                >
                  <h4 className="font-medium text-gray-900 dark:text-gray-100 mb-2">
                    {sectionHeaders.thesisResearch}
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">{thesis.title}</p>
                  <motion.a 
                    href={thesis.link}
                    className="inline-flex items-center gap-1.5 text-sm text-sky-600 dark:text-sky-400 hover:text-sky-700 dark:hover:text-sky-500"
                    whileHover={{ x: 5 }}
                  >
                    <ExternalLink className="w-4 h-4" />
                    {viewPublicationText}
                  </motion.a>
                </motion.div>
              )*/}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

// Main Education Component
const Education = () => {
  const { t } = useTranslation();

  const educationData: EducationData[] = [
    {
      degree: t('education.degree.bachelorTitle'),
      university: t('education.degree.university'),
      location: t('education.degree.location'),
      period: t('education.degree.period'),
      achievements: [
        t('education.achievements.deansList'),
        t('education.achievements.award'),
      ],
      courses: [
        t('education.courses.algorithms'),
        t('education.courses.software'),
        t('education.courses.networks'),
        t('education.courses.database'),
      ],
      thesis: {
        title: t('education.thesis.title'),
        link: "https://example.com/thesis"
      }
    }
  ];

  const sectionHeaders: SectionHeaders = {
    keyCourses: t('education.sectionHeaders.keyCourses'),
    achievements: t('education.sectionHeaders.achievements'),
    thesisResearch: t('education.sectionHeaders.thesisResearch')
  };

  const { scrollYProgress } = useScroll();
  const titleY = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [50, 0]),
    { damping: 15, stiffness: 50 }
  );

  return (
    <div className="relative overflow-hidden py-6 bg-white/70 dark:bg-gray-900/70" id="education">
      <ParallaxDecoration />
      
      <motion.div 
        className="max-w-6xl mx-auto p-4 sm:p-6 mt-16 sm:mt-24 lg:mt-32 transform-gpu"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <motion.div 
          className="mb-12 lg:mb-16 text-center lg:text-left"
          style={{ y: titleY }}
        >
          <motion.h2 
            className="text-3xl sm:text-4xl font-bold bg-gradient-to-r 
                      from-gray-900 to-gray-700 dark:from-gray-100 dark:to-gray-300 bg-clip-text text-transparent"
          >
            {t('education.title')}
          </motion.h2>
          <motion.p className="mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto lg:mx-0">
            {t('education.description')}
          </motion.p>
        </motion.div>

        <div className="space-y-6 relative z-10">
          {educationData.map((edu, index) => (
            <EducationCard 
              key={index}
              {...edu}
              index={index}
              sectionHeaders={sectionHeaders}
              viewPublicationText={t('education.thesis.viewPublication')}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Education;