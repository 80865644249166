import React from 'react';
import { Github, Twitter, Mail, Linkedin, ArrowUp, Globe } from 'lucide-react';
import { useTranslation } from '../../i18n/useTranslation.ts';
import logo from '../media/logo.svg';

interface FooterTranslations {
  'flowagenda-footer': {
    brand: {
      tagline: string;
    };
    sections: {
      product: {
        title: string;
        features: string;
        documentation: string;
        roadmap: string;
      };
      author: {
        title: string;
        website: string;
        github: string;
        linkedin: string;
      };
    };
    bottomBar: {
      rights: string;
      backToTop: string;
    };
    accessibility: {
      logo: string;
      socialLinks: {
        github: string;
        twitter: string;
        linkedin: string;
        email: string;
      };
    };
  };
}

const Footer = () => {
  const { t } = useTranslation();
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="bg-white dark:bg-gray-900 border-t border-gray-100 dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-6 py-12">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* Brand Section */}
          <div className="col-span-1 md:col-span-2">
            <div className="flex items-center space-x-3 mb-4">
              <img 
                src={logo} 
                alt="FlowAgenda" 
                className="w-6 h-6"
              />
              <h2 className="text-lg font-semibold bg-gradient-to-r from-neutral-600 to-neutral-400 dark:from-neutral-300 dark:to-neutral-500 bg-clip-text text-transparent">
                FlowAgenda
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 mb-6 max-w-md">
              {t('flowagenda-footer.brand.tagline')}
            </p>
          </div>

          {/* Product Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white mb-4">
              {t('flowagenda-footer.sections.product.title')}
            </h3>
            <ul className="space-y-3">
              <li>
                <a 
                  onClick={scrollToSection('features')}
                  className="text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 text-sm transition-colors cursor-pointer"
                >
                  {t('flowagenda-footer.sections.product.features')}
                </a>
              </li>
              <li>
                <a 
                  onClick={scrollToSection('documentation')}
                  className="text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 text-sm transition-colors cursor-pointer"
                >
                  {t('flowagenda-footer.sections.product.documentation')}
                </a>
              </li>
              <li>
                <a 
                  onClick={scrollToSection('roadmap')}
                  className="text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 text-sm transition-colors cursor-pointer"
                >
                  {t('flowagenda-footer.sections.product.roadmap')}
                </a>
              </li>
            </ul>
          </div>

          {/* Author Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white mb-4">
              {t('flowagenda-footer.sections.author.title')}
            </h3>
            <ul className="space-y-3">
              <li>
                <a 
                  href="https://www.qiyijiazhen.com" 
                  className="text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 text-sm transition-colors flex items-center gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Globe className="w-4 h-4" />
                  {t('flowagenda-footer.sections.author.website')}
                </a>
              </li>
              <li>
                <a 
                  href="https://github.com/kiwigaze" 
                  className="text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 text-sm transition-colors flex items-center gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Github className="w-4 h-4" />
                  {t('flowagenda-footer.sections.author.github')}
                </a>
              </li>
              <li>
                <a 
                  href="https://linkedin.com/in/yijiazhenqi" 
                  className="text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 text-sm transition-colors flex items-center gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin className="w-4 h-4" />
                  {t('flowagenda-footer.sections.author.linkedin')}
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="flex flex-col md:flex-row justify-between items-center mt-12 pt-8 border-t border-gray-100 dark:border-gray-800">
          <p className="text-gray-500 dark:text-gray-400 text-sm">
            © {new Date().getFullYear()} Qi Yijiazhen. {t('flowagenda-footer.bottomBar.rights')}
          </p>
          <button
            onClick={scrollToTop}
            className="mt-4 md:mt-0 flex items-center space-x-2 text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 text-sm transition-colors"
          >
            <span>{t('flowagenda-footer.bottomBar.backToTop')}</span>
            <ArrowUp className="w-4 h-4" />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;