import React, { useState } from 'react';
import { Moon, Globe, Calendar, Shield } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from '../../i18n/useTranslation.ts';
import darkmode from '../media/2.png';
import language from '../media/3.png';
import events from '../media/4.png';
import security from '../media/5.png';

interface Feature {
  icon: JSX.Element;
  title: keyof typeof featureKeys;
  image: string;
}

// Using const assertion to ensure type safety for feature keys
const featureKeys = {
  darkMode: 'darkMode',
  multiLanguage: 'multiLanguage',
  oneClick: 'oneClick',
  privacy: 'privacy'
} as const;

const Features: React.FC = () => {
  const { t } = useTranslation();
  const [selectedFeature, setSelectedFeature] = useState<number | null>(null);

  const features: Feature[] = [
    {
      icon: <Moon className="h-8 w-8" />,
      title: featureKeys.darkMode,
      image: darkmode
    },
    {
      icon: <Globe className="h-8 w-8" />,
      title: featureKeys.multiLanguage,
      image: language
    },
    {
      icon: <Calendar className="h-8 w-8" />,
      title: featureKeys.oneClick,
      image: events
    },
    {
      icon: <Shield className="h-8 w-8" />,
      title: featureKeys.privacy,
      image: security
    }
  ];

  return (
    <section className="py-20 bg-white dark:bg-gray-900 relative overflow-hidden" id='features'>
      {/* Background Animation */}
      <motion.div 
        className="absolute -top-1/2 -right-1/2 w-96 h-96 bg-blue-500/20 dark:bg-blue-400/10 rounded-full blur-3xl"
        animate={{
          y: [0, 20, 0],
          rotate: [0, 10, 0],
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="container mx-auto px-6 relative z-10">
        {/* Section Header */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-blue-400 dark:from-blue-400 dark:to-blue-200 bg-clip-text text-transparent">
            {t('flowagenda-features.title')}
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t('flowagenda-features.subtitle')}
          </p>
        </motion.div>

        {/* Features Grid */}
        <div className="w-full mx-auto space-y-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="group relative"
              onHoverStart={() => setSelectedFeature(index)}
              onHoverEnd={() => setSelectedFeature(null)}
            >
              <div className="bg-gray-50 dark:bg-gray-800 rounded-3xl p-8 transition-all duration-300">
                {/* Feature Header */}
                <div className="flex items-start gap-6 mb-8">
                  <motion.div 
                    className="flex-shrink-0 p-3 bg-blue-100 dark:bg-blue-900/50 rounded-xl"
                    whileHover={{ scale: 1.1, rotate: 10 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="text-blue-600 dark:text-blue-400">
                      {feature.icon}
                    </div>
                  </motion.div>
                  <div>
                    <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">
                      {t(`flowagenda-features.items.${feature.title}.title`)}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      {t(`flowagenda-features.items.${feature.title}.description`)}
                    </p>
                  </div>
                </div>

                {/* Feature Image */}
                <div className="relative overflow-hidden rounded-2xl">
                  <motion.img
                    src={feature.image}
                    alt={t(`flowagenda-features.items.${feature.title}.title`)}
                    className="w-full h-auto rounded-2xl shadow-lg"
                    initial={{ scale: 1 }}
                    animate={{
                      scale: selectedFeature === index ? 1.05 : 1,
                      y: selectedFeature === index ? -10 : 0,
                    }}
                    transition={{ duration: 0.3 }}
                  />
                  
                  {/* Hover Overlay */}
                  <AnimatePresence>
                    {selectedFeature === index && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 bg-blue-600/10 dark:bg-blue-400/20 rounded-2xl"
                      />
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;