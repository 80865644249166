import React from 'react';
import { Github, Twitter, Linkedin, Mail, Download } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTranslation } from '../i18n/useTranslation.ts';
import Tooltip from '../components/tooltip/Tooltip.tsx';

const Contact = () => {
  const { t } = useTranslation();

  const socialLinks = [
    { icon: Github, href: "https://github.com/KiwiGaze", label: "contact.social.github" },
    { icon: Twitter, href: "https://x.com/QiYijiazhen", label: "contact.social.twitter" },
    { icon: Linkedin, href: "https://www.linkedin.com/in/yijiazhenqi/", label: "contact.social.linkedin" },
    { icon: Mail, href: "mailto:qiyijiazhen@gmail.com", label: "contact.social.email" },
    { icon: Download, href: "https://drive.google.com/file/d/1sCS7oYEzQsMz2oW85PXDIQAYEOPxf208/view?usp=sharing", label: "contact.social.resume", download: true }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const socialIconsVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.6
      }
    }
  };

  const iconVariants = {
    hidden: { scale: 0 },
    visible: { 
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    },
    hover: { 
      scale: 1.2,
      rotate: 5,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  const handleContactClick = () => {
    window.location.href = "mailto:qiyijiazhen@gmail.com";
  }

  return (
    <div className="relative overflow-hidden py-6 bg-white dark:bg-gray-900">
      <motion.div 
        className="max-w-6xl mx-auto p-4 sm:p-6 mt-16 sm:mt-24" 
        id="contact"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
        variants={containerVariants}
      >
        <div className="bg-gradient-to-br from-blue-100/80 via-gray-100/80 to-indigo-50/80 dark:from-blue-900/80 dark:via-gray-800/80 dark:to-indigo-700/80 backdrop-blur-xl border border-white/20 dark:border-gray-700/20 rounded-3xl overflow-hidden">
          <motion.div 
            className="max-w-3xl mx-auto text-center p-8 sm:p-12"
            variants={containerVariants}
          >
            <motion.h2 
              className="text-4xl sm:text-5xl font-semibold mb-8 bg-gradient-to-br from-gray-800 to-gray-900 dark:from-gray-100 dark:to-gray-300 bg-clip-text text-transparent"
              variants={itemVariants}
            >
              {t('contact.title')}
            </motion.h2>
            
            <motion.p 
              className="text-gray-600 dark:text-gray-300 text-lg sm:text-xl mb-10"
              variants={itemVariants}
            >
              {t('contact.subtitle')}
            </motion.p>
            
            <motion.div 
              className="bg-white/80 dark:bg-gray-800/80 rounded-2xl p-6 mb-12 backdrop-blur-sm"
              variants={itemVariants}
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.2 }
              }}
            >
              <motion.p 
                className="text-2xl sm:text-3xl font-medium text-gray-800 dark:text-gray-100"
                variants={itemVariants}
              >
                qiyijiazhen@gmail.com
              </motion.p>
            </motion.div>
            
            <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
              <motion.button 
                className="w-full sm:w-auto px-8 py-4 bg-black dark:bg-white text-white dark:text-black rounded-2xl hover:shadow-lg transition-all duration-300"
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.05,
                  transition: {
                    type: "spring",
                    stiffness: 400,
                    damping: 10
                  }
                }}
                whileTap={{ scale: 0.95 }}
                onClick={handleContactClick}
              >
                {t('contact.email')}
              </motion.button>
              
              <motion.div 
                className="flex gap-4"
                variants={socialIconsVariants}
              >
                {socialLinks.map(({ icon: Icon, href, label, download }) => (
                  <motion.a
                    key={label}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-4 rounded-xl backdrop-blur-sm bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                    variants={iconVariants}
                    whileHover="hover"
                    aria-label={t(label)}
                    download={download}
                  >
                    <Icon className="w-5 h-5 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white" />
                  </motion.a>
                ))}
              </motion.div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;